// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("/opt/build/repo/src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("/opt/build/repo/src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-commissions-js": () => import("/opt/build/repo/src/pages/commissions.js" /* webpackChunkName: "component---src-pages-commissions-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pay-js": () => import("/opt/build/repo/src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-payments-js": () => import("/opt/build/repo/src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-policies-privacy-js": () => import("/opt/build/repo/src/pages/policies/privacy.js" /* webpackChunkName: "component---src-pages-policies-privacy-js" */),
  "component---src-pages-policies-returns-js": () => import("/opt/build/repo/src/pages/policies/returns.js" /* webpackChunkName: "component---src-pages-policies-returns-js" */),
  "component---src-pages-products-js": () => import("/opt/build/repo/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-shop-js": () => import("/opt/build/repo/src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

