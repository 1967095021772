import { observable, action, decorate } from 'mobx'
import uuid from 'uuid/v4'

class GlobalStore {
  isMenuOpen = false
  isModalOpen = false
  isBusy = false
  messages = []

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen
  }

  closeMenu() {
    this.isMenuOpen = false
  }

  setIsBusy(isBusy) {
    this.isBusy = isBusy
  }

  addMessage(message, duration = 6000) {
    const id = uuid()
    this.messages.push({ id, message, duration })
  }

  removeMessage(id) {
    const messageIndex = this.messages.findIndex(m => m.id === id)
    if (messageIndex < 0) return
    this.messages.splice(messageIndex, 1)
  }

  clearMessages() {
    this.messages = []
  }

  dehydrate() {
    return {
      isMenuOpen: this.isMenuOpen,
      isModalOpen: this.isModalOpen,
      isBusy: this.isBusy,
      messages: this.messages,
    }
  }
}

decorate(GlobalStore, {
  isMenuOpen: observable,
  isModalOpen: observable,
  isBusy: observable,
  messages: observable,
  toggleMenu: action,
  closeMenu: action,
  setIsBusy: action,
  addMessage: action,
  removeMessage: action,
  clearMessages: action,
})

export default GlobalStore
