import { observable, action, decorate } from 'mobx'
import uuid from 'uuid/v4'
import axios from 'axios'
import axiosConfig from 'config/axios'
import cartConfig from 'config/cart'
import { getInLocal, setInLocal } from 'lib/common/local-storage'

const { maxItemQuantity: MAX_ITEM_QUANTITY } = cartConfig
const { url: API_URL, headers: API_HEADERS } = axiosConfig.webtask
const defaultCheckout = {
  email: '',
  shippingName: '',
  shippingCountry: '',
  shippingRegion: '',
  shippingCity: '',
  shippingZip: '',
  shippingAddress: '',
}
const defaultOrder = {
  id: '',
  taxes: [],
  shippingMethods: [],
  selectedShippingMethod: '',
}
const defaultCharge = {
  billingName: '',
  billingCountry: '',
  billingRegion: '',
  billingCity: '',
  billingZip: '',
  billingAddress: '',
  selectedShippingMethod: '',
  isSameShippingAddress: false,
}

class CartStore {
  storageKey = 'evilneon.cart'
  items = []
  recentlyAddedItem = null
  checkout = defaultCheckout
  order = defaultOrder
  orderError = ''
  charge = defaultCharge
  chargeError = ''

  constructor() {
    if (!global.localStorage) return
    const data = getInLocal(this.storageKey)

    if (!data) return
    const { items, checkout, order, charge } = data

    if (items) this.items = items
    if (checkout) this.checkout = checkout
    if (order) this.order = order
    if (charge) this.charge = charge
  }

  saveLocal() {
    const { items, checkout, order, charge } = this
    setInLocal(this.storageKey, { items, checkout, order, charge })
  }

  get itemsTotal() {
    let total = 0
    this.items.forEach(item => {
      const itemType =
        item && item.print && item.print.price ? 'print' : 'product'
      total += item.quantity * item[itemType].price
    })
    return total
  }

  get itemsCount() {
    let count = 0
    this.items.forEach(item => (count += item.quantity || 0))
    return count
  }

  findItemBySku(sku) {
    return this.items.find(i => i.sku === sku)
  }

  addItem(item) {
    const newItem = { ...item }
    let matchIndex

    this.items.forEach((currentItem, index) => {
      if (newItem.sku === currentItem.sku) {
        const newQuantity = newItem.quantity + currentItem.quantity

        matchIndex = index
        newItem.quantity =
          newQuantity > MAX_ITEM_QUANTITY ? MAX_ITEM_QUANTITY : newQuantity
      }
    })
    if (matchIndex >= 0) {
      this.items[matchIndex] = newItem
    } else {
      this.items.push(newItem)
    }
    this.setRecentlyAddedItem({ ...item, toastKey: uuid() })
    this.setOrder({ ...this.order, shippingMethods: [] })
    this.saveLocal()
  }

  createItemFromProductSku = (product, sku, quantity = 1) => ({
    sku,
    product,
    quantity,
    ...(sku !== product.sku
      ? { print: product.prints.find(p => p.sku === sku) }
      : null),
  })

  setRecentlyAddedItem(value) {
    this.recentlyAddedItem = value
  }

  updateItemQuantity(sku, quantity) {
    this.items.forEach((item, index) => {
      if (item.sku === sku) {
        if (quantity > 0) {
          this.items[index].quantity = quantity
        } else {
          this.items.splice(index, 1)
        }
        return
      }
    })
  }

  clearItems() {
    this.items = []
    this.saveLocal()
  }

  setCheckout(checkout) {
    this.checkout = checkout
    this.saveLocal()
  }

  setOrder(order) {
    this.order = order
    this.orderError = ''
    this.saveLocal()
  }

  setOrderError(error) {
    this.orderError = error
  }

  setCharge(charge) {
    this.charge = charge
    this.saveLocal()
  }

  setChargeError(error) {
    this.chargeError = error
  }

  async createOrder() {
    const defaultError = 'Error creating order'

    try {
      const { data } = await axios.post(
        `${API_URL}order-create`,
        { checkout: this.checkout, items: this.items },
        { headers: API_HEADERS },
      )
      if (data.success && data.order.id) {
        this.setOrder(data.order)
        return true
      }
      this.setOrderError(data.error || defaultError)
    } catch (error) {
      this.setOrderError(error.message || defaultError)
    }
    return false
  }

  async payOrder(source) {
    const defaultError = 'Error submitting order'

    try {
      const { data } = await axios.post(
        `${API_URL}order-pay`,
        {
          source,
          description: '',
          oid: this.order.id,
          items: this.items,
          checkout: this.checkout,
          charge: this.charge,
        },
        { headers: API_HEADERS },
      )
      if (data.success && data.order.id) {
        this.completeOrder()
        return data.order.id
      }
      this.setChargeError(data.error || defaultError)
    } catch (error) {
      this.setChargeError(error.message || defaultError)
    }
    return false
  }

  completeOrder() {
    this.items = []
    this.checkout = defaultCheckout
    this.order = defaultOrder
    this.charge = defaultCharge
    this.orderError = ''
    this.chargeError = ''
    this.saveLocal()
  }

  getPrintOrProduct = item => {
    return item[item.print ? 'print' : 'prodcut']
  }

  getItemPrice = item => {
    const printOrProduct = this.getPrintOrProduct(item)
    const price = printOrProduct ? this.getPrintOrProduct(item).price : 0

    return price * item.quantity
  }

  getItemSize = item => {
    const printOrProduct = this.getPrintOrProduct(item)

    return printOrProduct ? this.getPrintOrProduct(item).size : {}
  }

  getOrderTax = () => {
    return this.order.items.find(
      item => item.type.match(/tax/i) && item.description.match(/shipping/i),
    )
  }

  dehydrate() {
    return {
      items: this.items,
      recentlyAddedItem: this.recentlyAddedItem,
      checkout: this.checkout,
      order: this.order,
      orderError: this.orderError,
      charge: this.charge,
    }
  }
}

decorate(CartStore, {
  items: observable,
  recentlyAddedItem: observable,
  checkout: observable,
  order: observable,
  orderError: observable,
  charge: observable,
  chargeError: observable,
  setRecentlyAddedItem: action,
  setCheckout: action,
  setOrder: action,
  setOrderError: action,
  setCharge: action,
  setChargeError: action,
  addItem: action,
  updateItemQuantity: action,
  clearItems: action,
  createOrder: action,
  payOrder: action,
  completeOrder: action,
  setSelectedShippingMethod: action,
})

export default CartStore
