import { observable, action, decorate } from 'mobx'
import axios from 'axios'
import axiosConfig from 'config/axios'
import { getInLocal, setInLocal } from 'lib/common/local-storage'

const { url: API_URL, headers: API_HEADERS } = axiosConfig.webtask

class LeadStore {
  storageKey = 'evilneon.lead'
  lead = {}

  constructor() {
    if (!global.localStorage) return
    const { lead } = getInLocal(this.storageKey)

    if (!lead) return
    this.lead = lead
  }

  saveLocal() {
    setInLocal(this.storageKey, { lead: this.lead })
  }

  setLead(lead) {
    this.lead = lead
    this.saveLocal()
  }

  async submitLead(lead) {
    this.setLead(lead)
    try {
      const { data } = await axios.post(`${API_URL}lead`, lead, {
        headers: API_HEADERS,
      })
      if (data.success) {
        this.setLead({})
        return true
      }
    } catch (error) {
      return false
    }
  }

  dehydrate() {
    return {
      lead: this.lead,
    }
  }
}

decorate(LeadStore, {
  lead: observable,
  setLead: action,
})

export default LeadStore
