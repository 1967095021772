
import React from 'react'
import { Provider } from 'mobx-react'
import GlobalStore from './src/stores/global-store'
import CartStore from './src/stores/cart-store'
import LeadStore from './src/stores/lead-store'

export default ({ element }) => (
  <Provider
    global={new GlobalStore()}
    cart={new CartStore()}
    lead={new LeadStore()}
  >
    {element}
  </Provider>
)
