const webtaskPrefix =
  process.env.GATSBY_EVILNEON_ENV === 'production' ? '' : 'staging-'

export default {
  webtask: {
    url: `${process.env.GATSBY_EVILNEON_WEBTASK_ENDPOINT}/${webtaskPrefix}`,
    headers: {
      accept: 'application/json',
      'accept-language': 'en_US',
      'content-type': 'application/json',
      Authorization: `Bearer ${process.env.GATSBY_EVILNEON_WEBTASK_TOKEN}`,
    },
  },
}
